
@import "material/angular-material";
@import "bootstrap/bootstrap.configuration";
@import "bootstrap/bootstrap.components";
@import "bootstrap/bootstrap.overrides";

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';

@import "google-maps/google-maps";
@import "ngx-slider/ngx-slider";
@import "ngx-toastr/ngx-toastr";

@import "ng-bootstrap/ng-bootstrap.extensions";
