
/*

@use '@angular/material' as mat;
// Include non-theme styles for core.
@include mat.core();

// Define a theme.
$primary: mat.define-palette(mat.$purple-palette, 700, 500, 800);
$accent:  mat.define-palette(mat.$green-palette, A200, A100, A400);

$theme: mat.define-dark-theme((
  color: (
    primary: $primary,
    accent: $accent
  ),
  typography: mat.define-typography-config(),
  density: -5,
));


@include mat.all-component-themes($theme);

@include mat.typography-hierarchy($theme);

*/


@use '@angular/material' as mat;
@include mat.core();

$myapp-theme-primary: mat.m2-define-palette(mat.$m2-indigo-palette, A400, A100, A700);
$myapp-theme-accent: mat.m2-define-palette(mat.$m2-indigo-palette);
$myapp-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$myapp-theme: mat.m2-define-light-theme((
  color: (
    primary: $myapp-theme-primary,
    accent: $myapp-theme-accent,
    warn: $myapp-theme-warn,
  )
));

@include mat.all-component-themes($myapp-theme);

.cdk-overlay-container, .cdk-overlay-pane {
  z-index: 9999 !important;
}
