:root {
  --tw-ng-node-bg: #{$color-twist-green-700};
  --tw-ng-node-bg--offline: #{$color-twist-gray-400};
  --tw-ng-node-icon-color: #{$color-twist-gray-50};
}

html[data-bs-theme="dark"] {
  --tw-ng-node-bg: #{$color-twist-green-500};
  --tw-ng-node-bg--offline: #{$color-twist-gray-500};
  --tw-ng-node-icon-color: #{$color-twist-gray-700};
}

svg {

  g.device-node {

    .node-background {
      fill: var(--tw-ng-node-bg);

      .s-offline & {
        fill: var(--tw-ng-node-bg--offline);
      }

      .s-joining & {
        fill: var(--tw-info);
      }

      .s-leaving & {
        fill: var(--tw-warning);
      }
    }

    .node-icon {
      fill: var(--tw-ng-node-icon-color);
    }

  }

}

.device-notification {
  //border: 1px solid;
  padding: 0.3em 1em;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-icon {
    width: 25px;
    margin-right: 0.5rem;
  }

  &-value {
    //font-size: 1rem;
    //line-height: 1;
    font-size: 0.9em;
    font-weight: 400;
  }

  &-note {
    font-size: 0.9em;
    color: var(--tw-secondary-color)
  }
}

.device-metric-grid {
  display: grid;
  gap: calc($spacer * 0.75);
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 4.5em;
  margin-bottom: 1em;


  @include media-breakpoint-up(xxl) {

    ///grid-template-columns: 1fr 1fr 1fr;

  }
}

.device-metric {
  --pw-columns: 1;
  grid-column: span var(--pw-columns);

  &.danger {
    border-color: var(--#{$prefix}danger);
  }

  &.success {
    border-color: var(--#{$prefix}success);
  }

  &.warning {
    border-color: var(--#{$prefix}warning);
  }

  &.columns-4 {
    --pw-columns: 4;
  }

  &.columns-3 {
    --pw-columns: 3;
  }

  &.columns-2 {
    --pw-columns: 2;
  }

  border: 1px  solid;
  padding: 0.5em 1em;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-icon {
    width: 32px;
    margin-right: 0.5rem;
  }

  &-value {
    //font-size: 1rem;
    //line-height: 1;
    font-size: 0.9em;
    font-weight: 400;
  }

  &-note {
    font-size: 0.9em;
    color: var(--tw-secondary-color)
  }
}
