
















.auth-page {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  &__wrapper{
    flex: 1 1 auto;
  }
  &__column{
    display: flex;
    justify-items: center;
    align-items: center;
    &--left{
      @extend .inverse !optional;
    }
    &--right{

    }
  }
  &__content{
    flex: 1 1 auto;
    padding: 1rem;
    @include media-breakpoint-up(md){
      padding: 3rem;
    }
    @include media-breakpoint-up(lg){
      padding: 6rem;
    }
  }
  &__subtitle{
    @extend .text-muted;
    font-size: 1.1em;
  }
}
