//
// card.scss
//

.card {
  box-shadow: $card-box-shadow;
  margin-bottom: $spacer;

  &-header {
   // background-color: deeppink;
  }

  &-title {
   // background-color: #ed3248;
  }

  .header-title {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: $font-size-base;
    margin-top: 0;
  }

  .card-drop {
    font-size: 20px;
    color: inherit;
  }

  .card-actions {
    float: right;
    height: 1rem;

    .nav {
      color: inherit;
      font-size: 1rem;
      margin-right: -0.5rem;
      line-height: 1;

      &-link{
        padding: 0 0.5rem;
        color: inherit;
      }

      /*&.collapsed {
        i {
          &:before {
            content: "\F0415";
          }
        }
      }*/
    }
  }
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;

  .header-title {
    margin-bottom: 0;
  }
}

//Card disable loading (Custom Cards)
.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: $card-border-radius;
  background: var(--#{$prefix}tertiary-bg);
  opacity: 0.8;
  cursor: progress;

  .card-portlets-loader {
    background-color: var(--#{$prefix}dark);
    animation: rotatebox 1.2s infinite ease-in-out;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -($spacer * 0.5);
    margin-top: -($spacer * 0.5);
  }
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

// Pricing card
.card-pricing {
  position: relative;

  .card-pricing-plan-name {
    padding-bottom: 20px;
  }

  .card-pricing-icon {
    font-size: 22px;
    box-shadow: $box-shadow;
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
  }

  .card-pricing-price {
    padding: 30px 0 0;

    span {
      font-size: 40%;
      color: var(--#{$prefix}gray-600);
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .card-pricing-features {
    color: var(--#{$prefix}gray-600);
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0;

    li {
      padding: 15px;
    }
  }
}

.card-pricing-recommended {
  @include media-breakpoint-up(lg) {
    margin-top: -1.9375rem;
  }

  .card-pricing-plan-tag {
    background-color: rgba($danger, 0.2);
    color: $danger;
    padding: 5px 0;
    font-weight: 700;
    border-radius: 0.25rem 0.25rem 0 0;
    margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  }
}

// Custom card height
.card-h-100 {
  height: calc(100% - #{$spacer});
}
