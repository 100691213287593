.installation-header-widget{

}


.debug-button {
  position: fixed;
  bottom: 3em;
  right: 3em;
  z-index: 10;
}
