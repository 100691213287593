
.parameter-widget-grid {

  display: grid;
  gap: calc($spacer * 0.75);
  grid-template-columns: repeat(auto-fill, minmax(180px, 180px));
  //grid-auto-columns: minmax(200px, 300px);
  grid-auto-rows: 80px;

}


.parameter-widget {
  --pw-columns: 1;
  grid-column: span var(--pw-columns);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, calc(180px - $spacer)));
  align-items: center;
  gap: 1rem;

  &.columns-4 {
    --pw-columns: 4;
  }

  &.columns-3 {
    --pw-columns: 3;
  }

  &.columns-2 {
    --pw-columns: 2;
  }


  &__parameter {
    display: flex;
    flex-direction: row;
    align-items: center;
    //background-color: deeppink;

    &-icon {
      width: 40px;
      margin-right: 0.5rem;
    }

    &-value {
      font-size: 0.9rem;
      font-weight: 400;
      text-align: left;
    }

    &-note {
      color: var(--tw-secondary-color)
    }

  }

}

button.parameter-widget {
  &:hover {
    background-color: var(--tile-bg-subtle);
  }
}
