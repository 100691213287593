:root {
  --tile-gap: #{$spacer};
  --tile-padding: 1rem;
  --tile-bg: var(--#{$prefix}bg-layer-1);
  --tile-bg-subtle: var(--#{$prefix}bg-layer-1-subtle);
  --tile-bg-emphasis: var(--#{$prefix}bg-layer-1-emphasis);
  --tile-border-radius: 1.5rem;
  --tile-inner-gap: calc(#{$spacer} / 2);

}

.bg-layer-1{
  background-color: var(--#{$prefix}bg-layer-1);
}

.bg-layer-1-subtle{
  background-color: var(--#{$prefix}bg-layer-1-subtle);
}

.bg-layer-1-emphasis{
  background-color: var(--#{$prefix}bg-layer-1-emphasis);
}

.bg-layer-2{
  background-color: var(--#{$prefix}bg-layer-2);
}

.bg-layer-2-subtle{
  background-color: var(--#{$prefix}bg-layer-2-subtle);
}

.bg-layer-2-emphasis{
  background-color: var(--#{$prefix}bg-layer-2-emphasis);
}

.tile {
  table {
    --tw-table-hover-bg: var(--tile-bg-subtle);
  }

  padding: 1rem;

  .tile &,
  .bg-layer-1 &{
    --tile-bg: var(--#{$prefix}bg-layer-2);
    --tile-bg-subtle: var(--#{$prefix}bg-layer-2-subtle);
    --tile-bg-emphasis: var(--#{$prefix}bg-layer-2-emphasis);
    --tile-border-radius: 1rem;
  }

  &.selected{
    outline: 2px var(--#{$prefix}bg-accent) solid;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2) ;
  }

  &.outline-danger {
    outline: 2px var(--#{$prefix}danger) solid;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2) ;
  }

  &.outline-success {
    outline: 2px var(--#{$prefix}success) solid;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2) ;
  }

  &.outline-warning {
    outline: 2px var(--#{$prefix}warning) solid;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2) ;
  }
}

.tile-grid {
  display: grid;
  gap: var(--tile-gap);

  .tile {

  }
}

.tile-list {
  display: flex;
  flex-direction: column;
  gap: var(--tile-gap);
}

.tile {
  border-radius: var(--tile-border-radius);
  background-color: var(--tile-bg);
  padding: var(--tile-padding);
  display: flex;
  flex-direction: column;
  gap: var(--tile-inner-gap);

  &__header {
    display: flex;
    align-items: center;
    justify-items: stretch;
    gap: var(--tile-inner-gap);
    flex-grow: 0;

    &-icon {
    }

    &-title {
      flex: 1 1 auto;
    }
    &-notification {

    }

    &-canary > img {
      max-height: 50px;
    }

    &-actions {
      .nav {
        color: inherit;
        font-size: 1rem;
        margin-right: -0.5rem;
        line-height: 1;

        &-link {
          padding: 0 0.5rem;
          color: inherit;
        }

        /*&.collapsed {
          i {
            &:before {
              content: "\F0415";
            }
          }
        }*/
      }
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    gap: var(--tile-inner-gap);
    flex-direction: column;
    align-items: stretch;
    padding-top: var(--tile-inner-gap);
  }


}
