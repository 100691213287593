﻿.bg-color {

  &--white,
  &--gray-light, 
  &--gray-medium,
  &--green
  {
    --#{$prefix}secondary: #{$color-body-inverse};
    --#{$prefix}body-color: #{$color-body-inverse};
    --#{$prefix}heading-color: #{$color-body-inverse};
    color: $color-body-inverse;
  }

  &--green {
    --#{$prefix}link-color: #{$color-body-inverse};
    --#{$prefix}link-color-rgb: #{red($color-body-inverse)} #{green($color-body-inverse)} #{blue($color-body-inverse)};
  }

  &--gray-dark
  {
    --#{$prefix}secondary: #{$color-body};
    --#{$prefix}body-color: #{$color-body};
    --#{$prefix}heading-color: #{$color-body};
    color: $color-body;
  }
  
  &--white {
    background-color: $color-body-bg-inverse;
  }

  &--gray-light {
    background-color: $color-twist-gray-50;
  }

  &--gray-medium {
    background-color: $color-twist-gray-100;
  }

  &--gray-dark {
    background-color: $color-twist-gray-900;
  }

  &--green {
    background-color: $color-twist-green-500;
  }

}