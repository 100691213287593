//
// light-mode.scss
//

// ================================ //
// ======== Custom Variable ======= //
// ================================ //

:root {

  $bg-layer-1: $color-twist-gray-100;
/*  $bg-layer-2: $color-twist-gray-50;*/
  $bg-layer-2: $color-twist-gray-200;

  --#{$prefix}text-color-subtle: #{$color-twist-gray-400};

  --#{$prefix}bg-layer-1: #{$bg-layer-1};
  --#{$prefix}bg-layer-1-subtle: #{shift-color($bg-layer-1, -20%)};
  --#{$prefix}bg-layer-1-emphasis: #{shift-color($bg-layer-1, 10%)};

  /*--#{$prefix}bg-layer-2: #{$bg-layer-2};
  --#{$prefix}bg-layer-2-subtle: #{shift-color($bg-layer-2, -20)};
  --#{$prefix}bg-layer-2-emphasis: #{shift-color($bg-layer-2, 10)};*/

  --#{$prefix}bg-layer-2: #{$bg-layer-2};
  --#{$prefix}bg-layer-2-subtle: #{shift-color($bg-layer-2, -20%)};
  --#{$prefix}bg-layer-2-emphasis: #{shift-color($bg-layer-2, 10%)};

  --#{$prefix}bg-accent: #{$color-twist-green-700};

  --#{$prefix}backdrop-background: rgba(100, 100, 100, 0.5);

  --#{$prefix}logo-lg-height: 38px;
  --#{$prefix}logo-sm-height: 38px;

  --#{$prefix}leftbar-width: 245px;
  --#{$prefix}leftbar-width-md: 160px;
  --#{$prefix}leftbar-width-sm: 70px;
  --#{$prefix}leftbar-condensed-height: 2000px;

  --#{$prefix}topbar-height: 70px;

  --#{$prefix}menu-item-icon-size: 1.2rem;
  --#{$prefix}menu-item-font-size: 0.9rem;
  --#{$prefix}menu-item-padding-x: 10px;
  --#{$prefix}menu-item-padding-y: 12px;

  --#{$prefix}menu-condensed-link-bg: #{$primary};

  // Footer Height
  --#{$prefix}footer-height: 60px;

  // Card Border Width
  --#{$prefix}theme-card-border-width: 0px;

  --#{$prefix}menu-bg: #{$gray-700};
  --#{$prefix}menu-item-color: $color-twist-white;
  --#{$prefix}menu-item-hover-color: $color-twist-white;
  --#{$prefix}menu-item-active-color: $color-twist-white;
  --#{$prefix}help-box-bg: #{rgba($white, 0.07)};


  --#{$prefix}topbar-bg: #313a46;
  --#{$prefix}topbar-item-color: #8391a2;
  --#{$prefix}topbar-item-hover-color: #bccee4;
  --#{$prefix}topbar-search-bg: #464f5b;
  --#{$prefix}topbar-user-bg: #3c4655;
  --#{$prefix}topbar-user-border: #414d5d;

}

// Dark Mode Topbar
html[data-bs-theme="dark"] {

  //$bg-layer-1: $color-twist-gray-800;
  $bg-layer-1: $color-twist-gray-700;
  $bg-layer-2: $color-twist-gray-650;

  --#{$prefix}text-color-subtle: #{$color-twist-gray-400};
 /* --#{$prefix}bg-layer-1: #{$bg-layer-1};
  --#{$prefix}bg-layer-1-subtle: #{shift-color($bg-layer-1, 10)};
  --#{$prefix}bg-layer-1-emphasis: #{shift-color($bg-layer-1, -10)};*/

  --#{$prefix}bg-layer-1: #{$bg-layer-1};
  --#{$prefix}bg-layer-1-subtle: #{shift-color($bg-layer-1, 10%)};
  --#{$prefix}bg-layer-1-emphasis: #{shift-color($bg-layer-1, -10%)};

  --#{$prefix}bg-layer-2: #{$bg-layer-2};
  --#{$prefix}bg-layer-2-subtle: #{shift-color($bg-layer-2, 10%)};
  --#{$prefix}bg-layer-2-emphasis: #{shift-color($bg-layer-2, -10%)};

  --#{$prefix}bg-accent: #{$color-twist-green-500};

  --#{$prefix}backdrop-background: rgba(0, 0, 0, 0.75);
}


// ================================ //
// ========== Main Menu =========== //
// ================================ //

// Background Light left-sidebar
/*html[data-menu-color="light"] {
  --#{$prefix}menu-bg: #ffffff;
  --#{$prefix}menu-item-color: #{$gray-700};
  --#{$prefix}menu-item-hover-color: #{$primary};
  --#{$prefix}menu-item-active-color: #{$primary};
  --#{$prefix}help-box-bg: #{$primary};

}

// Dark Left Sidebar
html[data-menu-color="dark"] {*/
/*
}
*/

// Brand Menu
html[data-menu-color="brand"] {
  --#{$prefix}menu-bg: #{linear-gradient(135deg, #6379c3 0%, #546ee5 60%)};
  --#{$prefix}menu-item-color: #cedce4;
  --#{$prefix}menu-item-hover-color: #ffffff;
  --#{$prefix}menu-item-active-color: #ffffff;
  --#{$prefix}help-box-bg: #{rgba($white, 0.07)};
}

// Dark Mode Left Sidebar
html[data-bs-theme="dark"][data-menu-color="light"],
html[data-bs-theme="dark"][data-menu-color="dark"] {
  --#{$prefix}menu-bg: #3a444e;
  --#{$prefix}menu-item-color: #8391a2;
  --#{$prefix}menu-item-hover-color: #bccee4;
  --#{$prefix}menu-item-active-color: #ffffff;
  --#{$prefix}help-box-bg: #{rgba($white, 0.07)};
}


// ================================ //
// ========== Topbar ============== //
// ================================ //

/*
// Light Topbar
html[data-topbar-color="light"] {
  --#{$prefix}topbar-bg: #ffffff;
  --#{$prefix}topbar-item-color: #{$gray-700};
  --#{$prefix}topbar-item-hover-color: #{$primary};
  --#{$prefix}topbar-search-bg: #{tint-color($gray-200,10%)};
  --#{$prefix}topbar-user-bg: #{lighten($gray-100, 1%)};
  --#{$prefix}topbar-user-border: #eef2f7;
}
*/

/*
// Dark Topbar
html[data-topbar-color="dark"] {
  --#{$prefix}topbar-bg: #313a46;
  --#{$prefix}topbar-item-color: #8391a2;
  --#{$prefix}topbar-item-hover-color: #bccee4;
  --#{$prefix}topbar-search-bg: #464f5b;
  --#{$prefix}topbar-user-bg: #3c4655;
  --#{$prefix}topbar-user-border: #414d5d;
}*/

// Brand Topbar
html[data-topbar-color="brand"] {
  --#{$prefix}topbar-bg: #{linear-gradient(135deg, #6379c3 0%, #546ee5 60%)};
  --#{$prefix}topbar-item-color: rgba(255, 255, 255, 0.7);
  --#{$prefix}topbar-item-hover-color: #ffffff;
  --#{$prefix}topbar-search-bg: rgba(255, 255, 255, 0.1);
  --#{$prefix}topbar-user-bg: rgba(255, 255, 255, 0.1);
  --#{$prefix}topbar-user-border: rgba(255, 255, 255, 0.15);
}

// Dark Mode Topbar
html[data-bs-theme="dark"][data-topbar-color="light"],
html[data-bs-theme="dark"][data-topbar-color="dark"] {
  --#{$prefix}topbar-bg: #3a444e;
  --#{$prefix}topbar-item-color: #8391a2;
  --#{$prefix}topbar-item-hover-color: #bccee4;
  --#{$prefix}topbar-search-bg: #464f5b;
  --#{$prefix}topbar-user-bg: #3c4655;
  --#{$prefix}topbar-user-border: #414d5d;
}
