.form-floating {
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      &::after {
        display: none;
      }
    }
  }
}

.form-group {
  position: relative;
}

.form-check-label {
  font-weight: $form-label-font-weight;
}

.validation-tooltip {
  position: absolute;
  display: none;
  top: 100%;
  z-index: 5;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 8px;
  font-size: 0.765625rem;
  margin-left: 1rem;
  color: #fff;
  background-color: var(--#{$prefix}danger);
  border-radius: var(--#{$prefix}border-radius);
  box-shadow: 1px 1px 5px $color-twist-gray-500;

  input:focus ~ & {
    display: block;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    left: 25px;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--#{$prefix}danger);
  }
}

.fixed-top,
.sticky-top
{
  top: calc($topbar-height + ($spacer * 2));
  //z-index: $zindex-levels;
}


.offcanvas-backdrop,
.modal-backdrop{
  backdrop-filter: blur(5px);
  transition: all 0.25s;
}

.modal-dialog{
  &.modal-fullscreen{
    .modal-content{
    }
  }
}
