//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
    +.breadcrumb-item {
        &::before {
          content: ">";
        }
    }
}
