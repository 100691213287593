.grid{

  &-span{
    &-columns{
      &-1{
        grid-column: span 1;
      }
      &-2{
        grid-column: span 2;
      }
      &-3{
        grid-column: span 3;
      }
      &-4{
        grid-column: span 4;
      }
      &-5{
        grid-column: span 5;
      }
      &-6{
        grid-column: span 6;
      }
    }

    &-rows{
      &-1{
        grid-row: span 1;
      }
      &-2{
        grid-row: span 2;
      }
      &-3{
        grid-row: span 3;
      }
      &-4{
        grid-row: span 4;
      }
      &-5{
        grid-row: span 5;
      }
      &-6{
        grid-row: span 6;
      }
    }
  }

}
