$model-controls-gap: calc($spacer / 2);

.model-tile-grid {
  --tile-columns: 3;
  --tile-height: 75px;
  //--tile-height: 80px;
  --ctrl-gap: calc(#{$spacer} / 2);
  --ctrl-bg: var(--#{$prefix}bg-layer-2);
  --ctrl-bg-disabled: var(--#{$prefix}bg-layer-2-subtle);
  --ctrl-bg-hover: #{$color-twist-green-500};
  --ctrl-color-hover: #{$color-twist-gray-900};

  //grid-template-columns: repeat(auto-fill, minmax(125px, 180px));
  grid-template-columns: repeat(auto-fill, minmax(100px, 165px));
  //grid-auto-columns: minmax(200px, 300px);
  grid-auto-rows: var(--tile-height);
}


.model-tile {
  grid-column: span 2;
  grid-row: span 6;
  display: flex;
  gap: var(--ctrl-gap);
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden;

  &--offline {
    &:before{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: var(--tw-body-bg);
      opacity: .8;
      z-index: 100;
    }
    &:after{
      position: absolute;
      content: 'PRODUCT OFFLINE';
      color: var(--tw-danger);
      text-shadow: 0 0 4px var(--tw-danger) ;
      font-size: 1.5em;
      font-weight: 500;
      text-align: center;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      left: 0;
      top: 40%;
      z-index: 100;
    }
  }

  &--collapsed {
    grid-column: span 2;
    grid-row: span 1;
  }

  &__header {
    display: flex;
    align-items: stretch;
    gap: var(--ctrl-gap);
    flex-grow: 0;

    &-icon {
      //aspect-ratio: 1;
      background-color: var(--ctrl-bg);
      border: none;
      border-radius: 1.5em;
      padding: 24px;
    }

    &-title {
      flex: 1 1 auto;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    gap: var(--ctrl-gap);
    flex-direction: column;
    align-items: stretch;
  }
}


.model-controls {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--ctrl-gap);

  &__button-grid {
    --columns: 3;

    width: 100%;
    display: grid;
    gap: var(--ctrl-gap);
    grid-template-columns: repeat(var(--columns), 1fr);

    > button.model-control {
      text-align: center;
    }

    &.columns-6 {
      --columns: 6;
    }

    &.columns-5 {
      --columns: 5;
    }

    &.columns-4 {
      --columns: 4;
    }

    &.columns-3 {
      --columns: 3;
    }

    &.columns-2 {
      --columns: 2;
    }
  }

  &__visualization {
    position: relative;
    color: $color-twist-gray-700;

    html[data-bs-theme="dark"] & {

      color: $color-twist-gray-300;
    }

    &-values {
      text-align: right;
      position: absolute;
      right: $spacer;
      bottom: 16px;

      h3 {

        color: $color-twist-green-500;
        font-weight: bold;
      }
    }
  }

  .model-control {
    background-color: var(--ctrl-bg);
    border: none;
    border-radius: 1.5em;
    padding: 16px 24px;

    &.disabled {
      background-color: var(--ctrl-bg-disabled);
    }


  }

  button.model-control {
    color: inherit;
    background-color: var(--ctrl-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;

    &:hover,
    &.active {
      background-color: var(--ctrl-bg-hover);
      color: var(--ctrl-color-hover);
    }

    &:active {
      color: initial;
      background-color: var(--ctrl-bg);
    }

    span {
      font-weight: normal;
      font-size: 12px;
    }
  }

}


.prerequisite-box {
  max-width: 400px;
}
