//
// _badges.scss
//

// Lighten badge (soft)

@each $state in map-keys($theme-colors) {
  .badge-#{$state} {
    --#{$prefix}badge-color: var(--#{$prefix}#{$state});
    background-color: var(--#{$prefix}#{$state}-bg);

    &[href] {
      color: var(--#{$prefix}#{$state});
      text-decoration: none;
      background-color: var(--#{$prefix}#{$state}-bg);

      &:is(:hover,:focus) {
        color: var(--#{$prefix}#{$state});
        text-decoration: none;
        background-color: var(--#{$prefix}#{$state}-bg);
      }
    }
  }
}

@each $state in map-keys($theme-colors) {
  .badge-#{$state}-lighten {
    --#{$prefix}badge-color: var(--#{$prefix}#{$state});
    background-color: var(--#{$prefix}#{$state}-bg-subtle);

    &[href] {
      color: var(--#{$prefix}#{$state});
      text-decoration: none;
      background-color: var(--#{$prefix}#{$state}-bg-subtle);

      &:is(:hover,:focus) {
        color: var(--#{$prefix}#{$state});
        text-decoration: none;
        background-color: var(--#{$prefix}#{$state}-bg-subtle);
      }
    }
  }
}

// Outline badge
@each $state in map-keys($theme-colors) {
  .badge-#{$state}-outline {
    --#{$prefix}badge-color: var(--#{$prefix}#{$state});
    border: 1px solid var(--#{$prefix}#{$state});
    background-color: transparent;

    &[href] {
      color: var(--#{$prefix}#{$state});
      text-decoration: none;
      background-color: var(--#{$prefix}#{$state}-bg-subtle);

      &:is(:hover,:focus) {
        color: var(--#{$prefix}#{$state});
        text-decoration: none;
        background-color: var(--#{$prefix}#{$state}-bg-subtle);
      }
    }
  }
}

.badge {
  // vertical-align: middle;
  // line-height: 1.1;
  padding: $badge-padding-y + .1em $badge-padding-x $badge-padding-y $badge-padding-x;

  &.badge-sm {
    padding: $badge-padding-y + .05em $badge-padding-x - .1em $badge-padding-y - .1em $badge-padding-x - .1em;
    font-size: 0.75em;
    transform: translateY(-.2em);
  }

  &.badge-lg {
    padding: $badge-padding-y * 2 $badge-padding-x * 2.5;
    font-size: $badge-font-size + 0.1em;
  }

}
