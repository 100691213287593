.svg-icon {
  --icon-height: 1em;

  &--size {
    &-small {
      --icon-height: 1em;
    }

    &-normal {
      --icon-height: 1.2em;
    }

    &-large {
      --icon-height: 1.5em;
    }

    &-xl {
      --icon-height: 2em;
    }

    &-xxl {
      --icon-height: 3em;
    }

    &-responsive {
      --icon-height: auto;
    }
  }

  svg {
    height: var(--icon-height);
  }
}
