//google maps info window overrides:
// todo: move to other file
.gm-style {

  --info-window-bg-color: var(--tw-body-bg);
  --info-window-text-color: var(--tw-body-color);

  .gm-style-iw {
    --scrollbar-width: thin;
    --scrollbar-width-legacy: 10px;

    border-radius: 20px;
    background-color: var(--info-window-bg-color);
    color: var(--info-window-text-color);

    // close button:
    button.gm-ui-hover-effect > span {
      background-color: var(--info-window-text-color);
    }
  }

  /* Modern browsers with `scrollbar-*` support */
  @supports (scrollbar-width: auto) {
    .gm-style-iw {
      scrollbar-color: var(--info-window-bg-color) var(--info-window-bg-color);
      scrollbar-width: var(--scrollbar-width);
    }
  }

  /* Legacy browsers with `::-webkit-scrollbar-*` support */
  @supports selector(::-webkit-scrollbar) {
    .gm-style-iw::-webkit-scrollbar-thumb {
      background: var(--info-window-bg-color);
    }
    .gm-style-iw::-webkit-scrollbar-track {
      background: var(--info-window-bg-color);
    }
    .gm-style-iw::-webkit-scrollbar {
      max-width: var(--scrollbar-width-legacy);
      max-height: var(--scrollbar-width-legacy);
    }
  }


  button.gm-ui-hover-effect {
    transform: translate(-8px, 8px);
  }

  // the arrow
  .gm-style-iw-tc {
    filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.3));
    &:after {
      background-color: var(--info-window-bg-color);

    }
  }
}
