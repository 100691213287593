// Content
/*
$content-padding-desktop: 3rem 3rem 1.5rem !default;
$content-padding-mobile: 1.5rem 1.5rem .75rem !default;

a.link{
  cursor: pointer;
  text-decoration: underline;
}

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
  // background: $sidebar-bg;
}

.main {
  display: flex;
  width: 100%;
  min-width: 0;
  height: calc(100vh - 000px);
  //transition: $sidebar-transition;
  //background: $body-bg;
  flex-direction: column;
  overflow: hidden;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  //background-color: #9CA9BB;
}

.content {
  //padding: $content-padding-mobile;
  // background-color: #99E9C5;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  direction: ltr;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-items: stretch;
  justify-content: stretch;
  //height: ;

  @include media-breakpoint-up(md) {
    width: auto;
    //max-width: auto;
  }

  @include media-breakpoint-up(lg) {
    //padding: $content-padding-desktop;
  }
}

.router-wrapper {
  //border: 1px #1D2127 solid;
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-items: stretch;
  justify-content: stretch;
  position: relative;

  > * {
    flex: 1 1 auto;
    overflow: auto;

    &:first-child {
      // router outlet
      flex: 0 0 auto;
      overflow: hidden;
    }
  }
}

.page-wrapper {
}

.page-content {
  padding: 3rem 2rem;
}
*/
