.installation-list {

  &__item {
    margin-bottom: $spacer;
    position: relative;

  }

  &__item-status {
    position: absolute;
    top: calc($spacer - 3px);
    right: calc($spacer - 3px);

  }
}

.product-list {
  //margin-top: 2rem;
  //display: grid;
  //gap: $spacer;
  //grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* see notes below */

  &__item {
    position: relative;
  }

  .device-list{
    display: flex;
    flex-direction: column;
    gap: $spacer;
  }
  .device{
    background-color: rgba($gray-200, .5);
    padding: $spacer;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    gap: $spacer;
    >* {
      &:first-child{
        width: 32px;
      }
    }
  }

}

.status {
  &--warning {
    --status-color: orange;
  }

  &--error {
    --status-color: red;
  }

  &--disabled {
    --status-color: $gray-100;
  }
}

.status-bullet {
  position: relative;
  width: 13px;
  height: 13px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 10px;
    background-color: var(--status-color, green);
  }
}

.text-color-status {
  color: var(--status-color, var(--#{$prefix}body-color))
}

/*.tile-grid{

  display: grid;
  gap: $spacer;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); !* see notes below *!

}*/
/*
.tile {
  //background-color: var(--#{$prefix});
  background-color: rgba($gray-100, .5);
  padding: $spacer*1.5;
  border-radius: 20px;
  //border-right: 5px solid var(--status-color, transparent);
  //border: 1px solid var(--#{$prefix}border-color);

  &.active {
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px, $color-twist-green-500 0 0 0 2px;
  }

  &.hovered {
    background-color: rgba($gray-100, .7);
  }

  &.status--error {

  }

  &__header {
    border-bottom: 1px solid $gray-200;
    margin-bottom: $spacer;
  }

  &__body {
    font-size: .9em;
    //margin-bottom: $spacer;
  }

  &__footer {
    text-align: right;
  }


  &__title {
    font-size: 1.3em;

  }*/
/*
  dl {
    display: grid;
    //grid-template-columns: 200px 200px 200px;
    gap: 2em;
    grid-auto-columns: fit-content(200px);
    //grid-auto-columns: 1fr;
    grid-auto-flow: column;
    !*
          grid-template-rows: auto auto;
          grid-auto-columns: 1fr;*!

  }*/
/*

  .status-blocks {
    display: grid;
    gap: $spacer;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .status-block {
    position: relative;

    &__title {
      font-weight: bold;
      margin-bottom: .5em;
    }

    background-color: rgba($gray-200, .5);
    padding: $spacer;
    border-radius: 15px;

    &--error {
      background-color: rgba($red-300, .3);
      //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, red 0px 0px 0px 2px;
    }

    &_icon {
      width: 25px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .quick-chart {
    width: 200px;
    height: 100px;
  }

}
*/
