.form-group {
  @extend .mb-4;
}

.form-control,
.form-select,
.input-group-text,
{
  &-rounded {
    border-radius: 2.5rem;
  }
}
/*
.input-group {
  &.rounded {
    &:not(.has-validation) {
      > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
      > .dropdown-toggle:nth-last-child(n+3),
      > .form-floating:not(:last-child) > .form-control,
      > .form-floating:not(:last-child) > .form-select {
        border-top-right-radius: 0 ;
        border-bottom-right-radius: 0;
      }
    }
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}*/
/*

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}*/
