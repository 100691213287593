/*
.model-control {
*/
body {

  .ngx-slider {
    //background-color: $color-twist-gray-700;
    height: 10px;
    margin-top: 15px;
    //border-radius: 2rem;

    .ngx-slider-bar {
      height: 10px;
      border-radius: 2rem;
      background: var(--#{$prefix}bg-layer-1);
      //height: 2px;
    }

    .ngx-slider-selection {

      background: $color-twist-green-500;
    }

    .ngx-slider-pointer {
      width: 32px;
      height: 32px;
      top: -11px;
      background-color: $color-twist-green-500;

      &:after {
        background-color: var(--#{$prefix}bg-layer-1);
        left: 8px;
        top: 8px;
        width: 16px;
        height: 16px;
        border-radius: 2rem;
      }
    }

    .ngx-slider-bubble {
      color: inherit;
      //background-color: deeppink;
    }

    .ngx-slider-tick,
    .ngx-slider-tick.ngx-slider-selected {
      background-color: transparent;


      .ngx-slider-tick-legend {
        top: 32px;
        font-size: 12px;
      }
    }

  }

}

/*}*/

