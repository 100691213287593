.offcanvas{

  &.offcanvas-size{

    &-lg {
      --#{$prefix}offcanvas-width: 1000px;
      width: 1000px;
      max-width: 100%;
    }

    &-md {
      --#{$prefix}offcanvas-width: 600px;
      width: 600px;
      max-width: 100%;
    }
  }
}


