
.animate {
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  animation-fill-mode: backwards;

  &--fade {
    animation-name: animate-fade;
    animation-timing-function: ease;
  }

  &--pop{
    animation-name: animate-pop;
  }


  &--blur{
    animation-name: animate-blur;
    animation-timing-function: ease;
  }

  &--glow {
    animation-name: animate-glow;
    animation-timing-function: ease;
  }

  /* Grow In */
  &--grow { animation-name: animate-grow; }

  /* Splat In */
  &--splat { animation-name: animate-splat; }

  /* Roll In */
  &--roll { animation-name: animate-roll; }
  /* Flip In */
  &--flip {
    animation-name: animate-flip;
    transform-style: preserve-3d;
    perspective: 1000px;
  }


  /* Spin In */
  &--spin {
    animation-name: animate-spin;
    transform-style: preserve-3d;
    perspective: 1000px;
  }
  /* Slide In */
  &--slide {
    animation-name: animate-slide;
  }

  /* Drop In */
  &--drop {
    animation-name: animate-drop;
    animation-timing-function: cubic-bezier(.77, .14, .91, 1.25);
  }

}


@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes animate-blur {
  0% {
    opacity: 0;
    filter: blur(15px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}


@keyframes animate-grow {
  0% {
    opacity: 0;
    transform: scale(1, 0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}


@keyframes animate-splat {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(20deg) translate(0, -30px);
  }
  70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg);
  }
  85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0) translate(0, 0);
  }
}


@keyframes animate-roll {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(360deg);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0deg);
  }
}



@keyframes animate-flip {
  0% {
    opacity: 0;
    transform: rotateX(-120deg) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg) scale(1, 1);
  }
}


@keyframes animate-spin {
  0% {
    opacity: 0;
    transform: rotateY(-120deg) scale(0.9, .9);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) scale(1, 1);
  }
}

@keyframes animate-slide {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes animate-drop {
  0% {
    opacity: 0;
    transform: translate(0,-300px) scale(0.9, 1.1);
  }
  95% {
    opacity: 1;
    transform: translate(0, 0) scale(0.9, 1.1);
  }
  96% {
    opacity: 1;
    transform: translate(10px, 0) scale(1.2, 0.9);
  }
  97% {
    opacity: 1;
    transform: translate(-10px, 0) scale(1.2, 0.9);
  }
  98% {
    opacity: 1;
    transform: translate(5px, 0) scale(1.1, 0.9);
  }
  99% {
    opacity: 1;
    transform: translate(-5px, 0) scale(1.1, 0.9);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1, 1);
  }
}

/* Animation Delays */
.animation-delay-1 {
  animation-delay: 0.1s;
}
.animation-delay-2 {
  animation-delay: 0.2s;
}
.animation-delay-3 {
  animation-delay: 0.3s;
}
.animation-delay-4 {
  animation-delay: 0.4s;
}
.animation-delay-5 {
  animation-delay: 0.5s;
}
.animation-delay-6 {
  animation-delay: 0.6s;
}
.animation-delay-7 {
  animation-delay: 0.7s;
}
.animation-delay-8 {
  animation-delay: 0.8s;
}
.animation-delay-9 {
  animation-delay: 0.9s;
}
.animation-delay-10 {
  animation-delay: 1s;
}
.animation-delay-11 {
  animation-delay: 1.1s;
}
.animation-delay-12 {
  animation-delay: 1.2s;
}
.animation-delay-13 {
  animation-delay: 1.3s;
}
.animation-delay-14 {
  animation-delay: 1.4s;
}
.animation-delay-15 {
  animation-delay: 1.5s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .animate {
    animation: none !important;
  }
}
