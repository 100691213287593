//
// custom-variables.scss
//
$prefix : tw-;
//
// Twist Colors
$color-twist-white: #FFF;
$color-twist-black: #101114;

$color-twist-green-400: #99E9C5;
$color-twist-green-500: #6FDBAA;
$color-twist-green-600: #5EC295;
$color-twist-green-700: #3AAB78;

$color-twist-gray-50: #F3F7FB;
$color-twist-gray-100: #E1E7F0;
$color-twist-gray-200: #D2DBE8;
$color-twist-gray-250: #c6d2de;
$color-twist-gray-300: #BAC6D7;
$color-twist-gray-400: #9CA9BB;
$color-twist-gray-500: #808C9D;
$color-twist-gray-600: #4F5B6C;
$color-twist-gray-650: #333b48;
$color-twist-gray-700: #2A313C;
$color-twist-gray-800: #1D2127;
$color-twist-gray-900: #171A1F;
$color-twist-gray-950: #0a0e0e;

$color-body: $color-twist-white;
$color-body-bg: $color-twist-gray-900;
$color-body-inverse: $color-twist-gray-900;
$color-body-bg-inverse: $color-twist-white;

// Fonts
$font-family-inter: 'Inter', sans-serif;
$font-family-Rainer: 'RainerBold', sans-serif;

$logo-lg-height:                    var(--#{$prefix}logo-lg-height);
$logo-sm-height:                    var(--#{$prefix}logo-sm-height);

// Left Sidebar
$leftbar-width:                     var(--#{$prefix}leftbar-width);
$leftbar-width-md:                  var(--#{$prefix}leftbar-width-md);
$leftbar-width-sm:                  var(--#{$prefix}leftbar-width-sm);
$leftbar-condensed-height:          var(--#{$prefix}leftbar-condensed-height);

$menu-item-icon-size:               var(--#{$prefix}menu-item-icon-size);
$menu-item-font-size:               var(--#{$prefix}menu-item-font-size);
$menu-item-padding-x:               var(--#{$prefix}menu-item-padding-x);
$menu-item-padding-y:               var(--#{$prefix}menu-item-padding-y);

// Menu
$menu-bg:                                 var(--#{$prefix}menu-bg);  // bg-leftbar rename to menu-bg
$menu-item-color:                         var(--#{$prefix}menu-item-color);
$menu-item-hover-color:                   var(--#{$prefix}menu-item-hover-color);
$menu-item-active-color:                  var(--#{$prefix}menu-item-active-color);

// Topbar
$topbar-height:                     var(--#{$prefix}topbar-height);
$topbar-bg:                         var(--#{$prefix}topbar-bg);
$topbar-item-color:                 var(--#{$prefix}topbar-item-color);
$topbar-item-hover-color:           var(--#{$prefix}topbar-item-hover-color);
$topbar-search-bg:                  var(--#{$prefix}topbar-search-bg);
$topbar-user-bg:                    var(--#{$prefix}topbar-user-bg);
$topbar-user-border:                var(--#{$prefix}topbar-user-border);

// Footer
$footer-height:                     var(--#{$prefix}footer-height);


// Background left-sidebar
$menu-condensed-link-bg:            var(--#{$prefix}menu-condensed-link-bg);

// Boxed Layout
$boxed-layout-width:                1300px;
$boxed-layout-bg:                   var(--#{$prefix}tertiary-bg);

// Helpbox
$help-box-bg:                       var(--#{$prefix}help-box-bg);

// Dropdown Large
$dropdown-lg-width:               320px;

// Chat widget
$chat-primary-user-bg:            var(--#{$prefix}chat-primary-user-bg);
$chat-secondary-user-bg:          var(--#{$prefix}chat-secondary-user-bg);

// Hero
$hero-bg:                         linear-gradient(to bottom,#6379c3,#546ee5);


// Transitions
$transition-appearance: background .4s ease-in-out, color .4s ease-in-out !default;
$transition-appearance-slow: background .6s ease-in-out, color .6s ease-in-out !default;
$transition-appearance-fast: background .1s ease-in-out, color .1s ease-in-out !default;
